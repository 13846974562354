import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-071226fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-content-padding" }
const _hoisted_2 = { class: "ion-text-nowrap" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "ion-text-nowrap"
}
const _hoisted_5 = {
  key: 2,
  class: "ion-text-nowrap"
}
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: "project-card ion-text-center",
    "router-link": $props.disableRouterLink ? undefined : {
    name: 'ProjectDetailPage',
    params: { id: $props.project.id, ...$props.extraRouterParams }
  },
    button: !$props.disableRouterLink,
    style: _normalizeStyle({ background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${$props.project.imageLink}) no-repeat scroll right center` })
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString($props.project.title), 1),
        (!$props.hideStatus)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
              ($props.userLocation == null)
                ? (_openBlock(), _createBlock(_component_ion_badge, {
                    key: 0,
                    color: $setup.getProjectStatusColor($props.project.status)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t($props.project.status)) + _toDisplayString($setup.getProjectDisplayProgress($props.project.progress)), 1)
                    ]),
                    _: 1
                  }, 8, ["color"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (!$props.hideDetails)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.project.address), 1))
          : _createCommentVNode("", true),
        (!$props.hideDetails)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($setup.t('projectFee')) + "HK$" + _toDisplayString($setup.numberWithCommas($props.project.amount)), 1))
          : _createCommentVNode("", true),
        ($props.userLocation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              ($props.userLocation.floor || $props.userLocation.unit)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($props.userLocation.floor) + "樓 " + _toDisplayString($props.userLocation.unit) + "室", 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        ($props.project.userRoles.length > 0)
          ? (_openBlock(), _createBlock(_component_ion_chip, {
              key: 4,
              style: {"background":"var(--ion-color-light)"},
              outline: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_avatar, null, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require('@/assets/avatar.svg')
                    }, null, 8, _hoisted_8)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.project.userRoles.map(r => $setup.getLocalizedStr(r, 'title', 'titleEn')).join(" / ")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ($props.disableRouterLink)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 5,
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToProjectDetailPage()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.t('details')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (!$props.hideDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["router-link", "button", "style"]))
}