
// icons
import { close, mail, person, call, chatbubbleEllipsesOutline, camera, trash, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader,
        IonInput, IonTextarea, IonFabButton, IonCard,
        loadingController, toastController, actionSheetController, onIonViewDidEnter } from '@ionic/vue';
import ProjectCard from "@/components/ProjectCard.vue";
import { computed, reactive, ref, watch } from 'vue';

// services
import CommonService from '@/services/CommonService';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { utils } from '@/composables/utils';

export default {
  name: 'FeedbackPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader,
                IonInput, IonTextarea, IonFabButton, IonCard, ProjectCard, },
  setup() {
    const { photos, takePhoto, showActionSheet } = usePhotoGallery();
    const route = useRoute();
    const { projectId, locationId } = route.params;
    const store = useStore();
    const project = computed(() => store.getters.getProjectById(projectId));
    const userLocation = computed(() => store.getters.getUserLocationByLocationId(locationId));
    const user = computed(() => store.state.user);
    const feedback = reactive({
      name: user.value.firstName || "",
      phone: user.value.phone || "",
      email: user.value.email || "",
      floor: "",
      unit: "",
      role: "",
      message: "",
    })

    // methods
    const { t } = useI18n();
    const { presentToast } = utils();
    const submitFeedback = async () => {
      const loading = await loadingController.create({});
      await loading.present();
      CommonService.createNewFeedback(feedback, photos.value, project.value);
      setTimeout(() => {
        loading.dismiss();
        feedback.message = "";
        presentToast(t('FeedbackPage.feedbackSubmitted'), 5000, 'top');
      }, 3000)
    }

    watch(user, (currUser) => { // triggered only when direct access to this page
      feedback.name = currUser.firstName;
      feedback.phone = currUser.phone;
      feedback.email = currUser.email;
    });
    onIonViewDidEnter(() => {
      if (userLocation.value) {
        feedback.floor = userLocation.value.floor;
        feedback.unit = userLocation.value.unit;
        feedback.role = userLocation.value.role;
      }
    })
    
    return {
      // icons
      close, mail, person, call, chatbubbleEllipsesOutline, camera,

      // variables
      feedback, project, photos,

      // methods
      t, submitFeedback,
      
      takePhoto, showActionSheet,
    }
  },
}
